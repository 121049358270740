<template>
  <div>
    <div class="mb-1 d-flex justify-content-between">
      <h3 class="font-weight-bolder">
        Paquetes de créditos
        <b-badge variant="dark">
          {{ totalCreditPackages }}
        </b-badge>
      </h3>
      <b-button
        variant="primary"
        :to="{ name: 'new-credit-bundle' }"
      >
        Agregar paquete
      </b-button>
    </div>
    <b-card no-body>
      <b-table
        :items="creditPackages"
        :fields="fields"
        empty-text="No se ha registrado ningún paquete de creditos"
      >
        <template #cell(price)="data">
          ${{ data.value | money }} USD
        </template>
        <template #cell(credit_amount)="data">
          <i class="fas fa-coins" /> {{ data.value | credits }}
        </template>
        <template #cell(active_status)="data">
          <b-badge
            pill
            :variant="(data.item.active_status === true) ? 'success' : 'warning'"
          >
            {{ data.item.active_status | status }}
          </b-badge>
        </template>
        <template #cell(tools)="data">
          <b-dropdown
            no-caret
            variant="outline"
            size="sm"
          >

            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" />
            </template>

            <b-dropdown-item
              :to="{ name: 'edit-credit-bundle', params: { credit_id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar paquete</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="showDeleteConfirmationModal(data.item)"
            >
              <feather-icon icon="Trash2Icon" />
              <span class="align-middle ml-50">Eliminar paquete</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  data() {
    return {
      fields: [
        {
          key: 'credit_amount',
          label: 'Créditos',
        },
        {
          key: 'price',
          label: 'Precio',
        },
        {
          key: 'active_status',
          label: 'Estatus',
        },
        {
          key: 'tools',
          label: 'Herramientas',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('credits', ['creditPackages', 'totalCreditPackages']),
  },
  created() {
    this.getCreditPackages({ by_active_status: 'all' })
  },
  methods: {
    ...mapActions('credits', ['getCreditPackages', 'editCreditPackage']),
    showDeleteConfirmationModal(creditPackage) {
      this.$bvModal.msgBoxConfirm('Porfavor confirma que deseas eliminar el paquete de creditos actual', {
        title: 'Mensaje de confirmación',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'SÍ',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value === true) {
            const packageToDelete = {
              ...creditPackage,
              active_status: false,
            }
            this.editCreditPackage(packageToDelete)
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Paquete de creditos eliminado',
                    icon: 'Trash2Icon',
                    variant: 'success',
                  },
                })
                this.getCreditPackages({ by_active_status: 'all' })
              })
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
